import React, { MutableRefObject, useRef, useState } from "react";
import { useParams } from "react-router";
import ReconnectingWebSocket from "reconnecting-websocket";
import Card from "./Card";
import "./Room.css";

interface Points {
  [id: string]: number;
}

const wsUrl =
  window.location.hostname === "localhost"
    ? "ws://localhost:8001/api"
    : "wss://estimaticity.vivacitylabs.com/api";
const cardValues = [0, 1, 2, 3, 5, 8, 13, 20, 40];

function Room() {
  const ws: MutableRefObject<ReconnectingWebSocket | undefined> = useRef();
  const connectionId: MutableRefObject<string | undefined> = useRef();

  const { roomId } = useParams();

  const [roomCount, setRoomCount] = useState(0);
  const [points, setPoints] = useState<Points>({});

  const selectedValue = connectionId.current && points[connectionId.current];

  React.useEffect(() => {
    ws.current = new ReconnectingWebSocket(`${wsUrl}/${roomId}`);

    ws.current.addEventListener("error", (e) => {
      console.log(e);
    });

    ws.current.addEventListener("message", (m) => {
      const process = async (b: Blob) => {
        const data = JSON.parse(await b.text());

        if (!connectionId.current) {
          connectionId.current = data.connectionId;
        }

        setRoomCount(data.roomCount);

        const newPoints = Object.fromEntries(
          Object.entries(data.points).map(([k, v]) => {
            return [k, parseInt(v as string, 10)];
          })
        );
        setPoints(newPoints);
      };
      void process(m.data);
    });

    return () => {
      connectionId.current = undefined;
      ws.current?.close();
    };
  }, [roomId]);

  const onCardClick = (value: number) => {
    ws.current?.send(`${value}`);
  };

  const onResetClick = () => {
    ws.current?.send("reset");
  };

  const anyConnected = roomCount > 0;
  const finished = anyConnected && Object.keys(points).length === roomCount;

  return (
    <div>
      <p>Room: {roomId}</p>
      <p>
        ConnectionId:{" "}
        {connectionId.current ? connectionId.current : "Not connected :("}
      </p>
      <p>Participants: {roomCount}</p>
      <button onClick={onResetClick}>Reset</button>

      <h3>Pick:</h3>
      <div className="Room-card-container">
        {cardValues.map((cardValue) => (
          <Card
            key={cardValue}
            value={cardValue}
            color={selectedValue === cardValue ? "yellow" : "lightgrey"}
            onCardClick={onCardClick}
          />
        ))}
      </div>

      {anyConnected && <h3>Results:</h3>}
      {finished && (
        <p>
          Avg:{" "}
          {(
            Object.values(points).reduce((s, p) => s + p, 0) / roomCount
          ).toFixed(1)}
        </p>
      )}

      <div className="Room-card-container">
        {Object.entries(points).map(([k, v]) => (
          <Card
            key={k}
            value={finished ? (v as number) : undefined}
            color={k === connectionId.current ? "yellow" : "lightgrey"}
            onCardClick={onCardClick}
          />
        ))}
      </div>
    </div>
  );
}

export default Room;
