import React from "react";
import "./Card.css";

interface CardProps {
  value?: number;
  color: string;
  onCardClick?: (value: number) => void;
}

function Card(props: CardProps) {
  const onClick = () => {
    if (props.onCardClick && props.value) {
      props.onCardClick(props.value);
    }
  };

  return (
    <div
      className="Card"
      style={{ backgroundColor: props.color }}
      onClick={onClick}
    >
      <p className="Card-text">{props.value}</p>
    </div>
  );
}

export default Card;
