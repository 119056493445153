import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Room from "./room/Room";

function Router() {
  const rand = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(0, 8);
  const url = `/rooms/${rand}`;
  return (
    <BrowserRouter>
      <Routes>
        <Route path="rooms/:roomId" element={<Room />} />
        <Route
          path="*"
          element={
            <p style={{ textAlign: "center" }}>
              Go to a room such as <a href={url}>{url}</a>
            </p>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
